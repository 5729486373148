import dynamic from "next/dynamic";
import Header from "../components/_App/Header";
import HomeBody from "../components/Home/HomeBody";
import apiClient from "../data/apiClient";

import { HOME_QUERY } from "../data/homeQuery";
const Footer = dynamic(() => import("../components/_App/Footer"), {
  ssr: false,
});
const ExtraPageFooter = dynamic(
  () => import("../components/Common/ExtraPageFooter"),
  {
    ssr: false,
  }
);

const Home = ({ data, footer, menu }) => {
  return (
    <>
      <Header {...menu}></Header>
      <HomeBody data={data}></HomeBody>
      <ExtraPageFooter></ExtraPageFooter>
      <Footer {...footer}></Footer>
    </>
  );
};

export async function getStaticProps() {
  const isAU = process.env.NEXT_PUBLIC_SITE_IS_AU == "true";
  const country = isAU ? "AU" : "NZ";
  const { data } = await (
    await apiClient()
  ).query({
    query: HOME_QUERY,
    variables: { filter: `data/country/iv eq '${country}'` },
    fetchPolicy: "no-cache",
  });

  const {
    queryHomepagesContents: homeContents,
    queryFooterContents: footerContents,
    queryMenuContents: menuContents,
  } = data;
  const homeContent = homeContents[0] != null ? homeContents[0].flatData : [];
  const footerContent =
    footerContents[0] != null ? footerContents[0].flatData : [];
  const menuContent = menuContents[0] != null ? menuContents[0].flatData : [];
  const keywords = isAU
    ? "Ryco Filters, Ryco Australia"
    : "Ryco Filters, Ryco New Zealand";
  return {
    props: {
      data: homeContent,
      footer: footerContent,
      menu: menuContent,
      seo: {
        title:
          "Search Filters | Fuel filters | Air filters | Oil filters - Ryco Filters",
        metadata:
          "Choose Ryco Filters for the best oil filters, air filters, fuel filters and vehicle products. The leading filter experts in Australia and New Zealand.",
        keywords: keywords,
        socialTitle:
          "Choose Ryco Filters for the best oil filters, air filters, fuel filters and vehicle products. The leading filter experts in Australia and New Zealand.",
        isNzOnly: false,
        url: "/",
      },
    },
    revalidate: 60 * 30, // in seconds
  };
}

export default Home;
